import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar, Footer } from './components/Components.jsx';
import { Home, MFMD, WhoWeAre, MFC, Logistics, MTM, Mediacomm, Foods, Discover, SocialMedia, B2BBusiness, Tips } from './containers/Containers.jsx';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      	<Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/who-we-are" element={<WhoWeAre />}></Route>
          <Route path="/message-from-chairman" element={<MFC />}></Route>
          <Route path="/message-from-md" element={<MFMD />}></Route>
          <Route path="/meet-the-team" element={<MTM />}></Route>
          <Route path="/discover" element={<Discover />}></Route>
          <Route path="/logistics" element={<Logistics />}></Route>
          <Route path="/news" element={<Mediacomm />}></Route>
          <Route path="/foods-limited" element={<Foods />}></Route>
          <Route path="/blogs/top-5-social-media-for-b2b-business" element={<SocialMedia />}></Route>
          <Route path="/blogs/social-media-to-promote-b2b-business" element={<B2BBusiness />}></Route>
          <Route path="/blogs/tips-for-evaluating-the-effectiveness-of-social-media" element={<Tips />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
