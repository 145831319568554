import MD from '../../assets/MD.jpg';
import './AboutUs.css';

function MFMD() {
    return (
        <div className="mfmd mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <img src={MD} alt="MD" className='img-fluid' width="200px"/>
                        <h6 className='picture-heading'>Maliha Rahham</h6>
                        <p className='picture-post'>Managing Director, Senoark</p>
                    </div>
                    <div className="col-md-9">
                        <h5 className='borderleft'>Managing Director's Message</h5>
                        <p>Ms Maliha Rahham is the Founder and Managing Director of Senoark, a technology-driven firm that works in many areas in Bangladesh. Ms Maliha, a third-generation businesswoman, is inspired by the key principle of combining "Goodness with Growth'' via her nation-building vision. Each of the Group's companies is dedicated to assisting in the development of world-class infrastructure capabilities in order to drive Bangladesh's economy.</p>

                        <p>For Ms Maliha, nation-building entails digitally modernizing Bangladesh. Nation development, according to Ms Maliha, entails strengthening the people of Bangladesh not just economically but also digitally. She aspires to give Bangladeshis high-quality, low-cost services that they may access from the comfort of their own homes.</p>

                        <p>Ms Maliha's vision is to make Bangladesh one of the most important players in legal technology by investing in accessible and inexpensive legal services for all those in need. BASIS and the Bangladesh Government honored her with the ICT National Award for the best legal service product.</p>

                        <p>The Group has a long history of collaborating with global businesses that wish to be a part of Bangladesh's economic narrative. These organizations' competence in their respective sectors, along with Senoark Group's project execution and market competencies, has resulted in long-term relationships. Some of those organizations are like Wander Woman & TransEnd.</p>

                        <p>Among her primary interests is the BA Khan Foundation, the Group's Corporate Social Responsibility arm. Education, healthcare, sustainable livelihoods, and community infrastructure development are among the Foundation's pan-Bangladesh efforts.</p>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default MFMD;