import Logo from '../../assets/logo white.png';
import './Navbar.css';

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <a href="/" className="navbar-brand">
                    <img src={Logo} alt="" width="150px" />
                </a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 px-3 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link" href="/#pricing">Home</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" id="navbarDropdown" role='button'data-bs-toggle="dropdown" aria-expanded="false" href="#">About Us</a>
                            <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                                <li><a className="dropdown-item" href="/who-we-are">Who We Are</a></li>
                                <li><a className="dropdown-item" href="/message-from-chairman">Message from Chairman</a></li>
                                <li><a className="dropdown-item" href="/message-from-md">Message from Managing Director</a></li>
                                <li><a className="dropdown-item" href="/meet-the-team">Meet the Team</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link" href="/discover">Business</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/about-us">Shop</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/about-us">Apps</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/about-us">Support</a>
                        </li>
                    </ul>
                    {/* <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                        <li className='nav-item p-2 icon'>
                            <a className="nav-link " href="">
                                <FontAwesomeIcon icon={faCartShopping} size='xl' color='white'/>
                            </a>
                        </li>
                    </ul> */}
                </div>

            </div>
        </nav>
    )
};

export default Navbar;