import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/logo.png';
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <div className="row pt-5 mt-5 border-top">
                    <div className="col-xl-2 col-sm-6">
                        <h5>Our Concerns</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item my-2">
                                <a href="https://senoarkdigital.com" className="nav-link p-0 text-muted">Senoark Digital</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/logistics" className="nav-link p-0 text-muted">Senoark Logistics</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">Senoark Marketplace</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/news" className="nav-link p-0 text-muted">Senoark News</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">Senoark Healthcare</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/foods-limited" className="nav-link p-0 text-muted">Senoark Foods Limited</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">Senoark Technologies</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-xl-2 col-sm-6">
                        <h5>Our Offerings</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">360 Marketing</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">Legal Support</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">Employee Health Support</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">Hosting support for your business</a>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="col-xl-2 col-sm-6">
                        <h5>Support</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">Explore Support</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">Locate us</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">FAQ</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="/" className="nav-link p-0 text-muted">Contact us</a>
                            </li>
                        </ul>
                    </div>



                    <div className="col-xl-2 col-sm-6">
                        <h5>Useful links</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item my-2">
                                <a href="https://asklegalx.com" className="nav-link p-0 text-muted">LegalX</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="https://legalxpartners.com" className="nav-link p-0 text-muted">LegalX Partners</a>
                            </li>
                            <li className="nav-item my-2">
                                <a href="https://yourlawyer.com" className="nav-link p-0 text-muted">Your Lawyer</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-xl-4 col-sm-12">
                        <h2>Connect with us</h2>
                        <div className="d-flex">
                            <FontAwesomeIcon className="icon" icon={faFacebook} size='1x'/>
                            <FontAwesomeIcon className="icon" icon={faInstagram} size='1x'/>
                            <FontAwesomeIcon className="icon" icon={faLinkedin} size='1x'/>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-row justify-content-start align-items-center py-3 border-top">
                    <img src={logo} alt="logo" className='logo' width="150px"/>
                    <p className="col-md-4 mb-0 text-muted copyright">
                        Copyright © 2022 Senoark. All rights reserved.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Footer;