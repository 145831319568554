import './Logistics.css';
import MapIcon from '../../assets/map icon.png';
import CalenderIcon from '../../assets/calendar con.png';
import CarIcon from '../../assets/car icon.png';
import BookIcon from '../../assets/book icon.png';
import Hygiene from '../../assets/Hygiene & safety.jpg';
import Teletalk from '../../assets/logos/1.png';
import {Component} from 'react';
import emailjs from '@emailjs/browser'

class Logistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            from : '',
            to: '',
            carType: '',
            date: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        let params = {
            customer_name: this.state.name,
            phone_number: this.state.phone,
            car_type: this.state.carType,
            from: this.state.from,
            to: this.state.to,
            date: this.state.date 
        }
        emailjs.send('service_lpvf24j', 'template_g17argi', params, 'AmdJgcouVY-G23tFO')
            .then(() => {
                alert("We have received your booking! We will get in touch with you as soon as possible");
            }, (err) => {
                console.error(err);
                alert("We have encountered an unknown error. Try refreshing the page or try after sometime");
            })
    }

    render() {
        return (
            <div className="logistics">
                <div className="row fullwidth">
                    <div className="col-md-6 formbanner d-flex justify-content-center align-items-center">
                        <div className="form">
                            <h2>Book your trip</h2>
                            <p>Experience a Safe & Hassle Free Journey</p>
                            <form onSubmit={this.handleSubmit}>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="from" aria-describedby="fromHelp" placeholder='Name' value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} required/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="from" aria-describedby="fromHelp" placeholder='Phone number' value={this.state.phone} onChange={(event) => this.setState({phone: event.target.value})}  required/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="from" aria-describedby="fromHelp" placeholder='From' value={this.state.from} onChange={(event) => this.setState({from: event.target.value})} required/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" id="to" aria-describedby="toHelp" placeholder='To' value={this.state.to} onChange={(event) => this.setState({to: event.target.value})} required/>
                                </div>
                                <div className="mb-3">
                                    <input type="date" className="form-control" id="journeyDate" aria-describedby="emailHelp" placeholder='Journey Date' value={this.state.date} onChange={(event) => this.setState({date: event.target.value})} required/>
                                </div>
                                <div className="mb-3">
                                    <select className="form-select" aria-label="Select Vehicle Type" value={this.state.carType} onChange={(event) => this.setState({carType: event.target.value})} required>
                                        <option selected>Select Vehicle Type</option>
                                        <option value="Hiace">Hiace</option>
                                        <option value="Noah">Noah</option>
                                        <option value="Sedan">Sedan</option>
                                        <option value="Coasters">Coasters</option>
                                    </select> 
                                </div>
                                
                                <div className="d-grid mx-auto">
                                    <button className="btn btn-primary" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 carbanner"></div>
                </div>
    
                <div className="travel text-center py-5">
                    <div className="container">
                        <h1>Travel around Bangladesh with us</h1>
    
                        <div className="howitworks">
                            <h3>How it works</h3>
                            <h2>Book your trip in just 4 simple steps</h2>
                            <div className="row g-2">
                                <div className="col-md-3">
                                    <img src={MapIcon} alt="MapIcon" />
                                    <p>Choose a location</p>
                                </div>
                                <div className="col-md-3">
                                    <img src={CalenderIcon} alt="CalenderIcon" />
                                    <p>Select Date</p>
                                </div>
                                <div className="col-md-3">
                                    <img src={CarIcon} alt="CarIcon" />
                                    <p>Select Vehicle Type</p>
                                </div>
                                <div className="col-md-3">
                                    <img src={BookIcon} alt="BookIcon" />
                                    <p>Book your trip</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="hygience">
                    <img src={Hygiene} alt="Hygiene" className='img-fluid' />
                </div>
    
                <div className="client text-center my-5">
                    <h1>We are proudly support by</h1>
                    <img src={Teletalk} alt="Teletalk" />
                </div>
            </div>
        )
    }
};

export default Logistics