import './Home.css';
import YourLawyer from '../../assets/YourLawyer.png';
import banner1 from '../../assets/1.png';
import banner2 from '../../assets/2.png';
import banner3 from '../../assets/3.png'
import video from '../../assets/background.mp4'
import Card from '../../components/Card/Card';
import Apps from './Apps';
import BlogCard from '../../components/Card/BlogCard';
import Czar from '../../assets/C\'zar.png'
import blog1 from '../../assets/blog1.jpg'
import blog2 from '../../assets/blog2.png'
import blog3 from '../../assets/blog3.jpg'

function Home() {

    return (
        <div className="home">
            <div id="carouselExampleIndicators" className='carousel slide' data-bs-ride='carousel' data-bs-interval="3000">
                <div className="carousel-indicators">
                    <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='0' className='active' aria-label='Slide 1'></button>
                    <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='1' aria-label='Slide 2'></button>
                    <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='2' aria-label='Slide 3'></button>
                </div>

                <div className="carousel-inner">
                    <div className="carousel-item active banner1">
                        <div className="carousel-caption">
                            <h1 className='heading'>Ensuring digital life for you</h1>
                            <p>Served more than 2,000 clients with our digital products</p>
                        </div>
                    </div>
                    <div className="carousel-item banner2">
                        <div className="carousel-caption">
                            <h1 className='heading'>The interconnected living of the future</h1>
                            <p>Grow your business digitally with our digital services today!</p>
                        </div>
                    </div>
                    <div className="carousel-item banner3">
                    </div>
                </div>
            </div>

            <div className="spotlight pt-3">
                <div className="container">
                    <div className="text-center">
                        <h1 className='heading'>In the spotlight</h1>
                        <p>The current top offers, new goods, and best-selling items</p>
                    </div>

                    <div className="pg mx-auto">
                        <div className="row align-items-center">
                            <div className="col-md-6 cont order-2 order-xxl-1 order-lg-1 order-xl-2">
                                <h2 className="heading pb-2">Learn more about our digital legal product</h2>
                                <p>Chat with an online legal professional with starting 249 BDT/Month</p>
                                <button type='button' className='button'>See More</button>
                            </div>

                            <div className="col-md-6 text-center order-1 order-xxl-2 order-lg-2 order-xl-2">
                                <img className="img-fluid" src={YourLawyer} alt="jio" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row mx-auto">
                    <div className="col-md-4">
                        <Card src={banner1} title="Telecure" text="Telecure is an online and offline based emerging health tech startup. We aim to provide pathology services & blood tests at an affordable price from the comfort of the customer's home." button="Learn More" link="https://www.facebook.com/telecurebd" />
                    </div>
                   <div className="col-md-4">
                        <Card src={banner2} title="LegalX" text="LegalX is a legal platform, which aims to combine the highest international standards and local expertise, in order to provide seamless assistance to the local population." button="Learn More" link="https://www.facebook.com/legalx.bd/" />
                    </div>
                    <div className="col-md-4">
                        <Card src={banner3} title="Senoark Digital" text="Senoark Digital is a 360 digital marketing and business consulting firm that specializes in corporate and SME growth and sales tactics. We also provide services like PR, Query management and SEO" button="Learn More" link="https://www.facebook.com/senoarklimited/" />
                    </div>
                </div>
            </div>

            <div className="digital">
                <div className="container text-center">
                    <h1 className="heading">Enrich your digital life</h1>
                    <p>Stream, shop, connect, and do much more with Senoark</p>
                </div>
                <div className="ad">
                    <div className="video-container">
                        <video autoPlay muted loop>
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                    <div className="w-content">
                        <h1>In a world of infinite possibilities, experience is everything</h1>
                    </div>
                </div>

            </div>

            <div className="all-apps">
                <Apps />
                <div className="text-center my-5">
                    <a href="/" className="abutton">View All Apps</a>
                </div>
            </div>

            <div className="opp">
                <div className="container">
                    <div className="pg mx-auto">
                        <div className="row align-items-center">
                            <div className="col-md-6 cont order-2 order-xxl-1 order-lg-1 order-xl-2">
                                <h2 className="heading pb-2">If you can smell it, you can almost taste it!</h2>
                                <p>Visit C'zar Gulshan outlet today!</p>
                                <a href="https://www.facebook.com/CzarCorporate" className='button'>Visit Now</a>
                            </div>

                            <div className="col-md-6 text-center order-1 order-xxl-2 order-lg-2 order-xl-2">
                                <img className="img-fluid" src={Czar} alt="jio" />
                            </div>
                         </div>
                    </div>
                </div>
            </div>

            <div className="blogs">
                <div className="container text-center">
                    <h1 className="heading">Making a difference</h1>
                    <p>Here is a handful of the many success stories.</p>
                    
                    <div className="row">
                        <div className="col-md-4">
                            <BlogCard src={blog1} title="The Top 5 Social Media Platforms for B2B Companies" text="The ideal platforms for advertising your products must be chosen carefully if you want your marketing initiatives to be effective." link="/blogs/top-5-social-media-for-b2b-business"/>
                        </div>
                        
                        <div className="col-md-4">
                            <BlogCard src={blog2} title="The Top 5 Social Media Platforms for B2B Companies" text="First, give your target audience some thought. Who are they? What are their interests? What sort of content do they engage?" link="/blogs/social-media-to-promote-b2b-business"/>
                        </div>
                        
                        <div className="col-md-4">
                            <BlogCard src={blog3} title="6 Tips for Evaluating the Effectiveness of Social Media" text="Determine the brand's primary social media goals. What motivated them to pick these channels? In order to ensure that the brand is speaking with" link="/blogs/tips-for-evaluating-the-effectiveness-of-social-media"/>
                        </div>
                    </div>

                    <div className="text-center my-5">
                        <a href="/" className="abutton">View All</a>
                    </div>
                </div>
            </div>


            {/* <div className="whoweare">
                <div className="content">
                    <div className="centerElm container">
                        <h1 className="title">Who We Are</h1>
                        <div className="text">
                            <p>An agency specialized in strategies for digital marketing, business consultancy and technology which helps organizations to boost their growth and maximize the sales. By continually analyzing your data over social media and websites, and helping you implement solutions to get the best quality leads and turn them into your clients.  </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="awards">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="title">Awards</div>
                            <p className='text'>
                            We have helped our client to win the prestigious “BASIS NATIONAL ICT AWARDS 2020” through our marketing efforts with utmost dedication and sincerity.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img src={Award} alt="Award" width="100%" height="90%" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="clients">
                <div className="container">
                    <div className="title">Clients</div>
                    <Clients />
                </div>
            </div>

            <div className="testimonials">
                <div className="container">
                    <div className="title">Testimonials</div>

                    <div className="T1 row align-items-center">
                        <div className="col-md-4 text-center">
                            <img src={T1} alt="" className="testPicture" />
                        </div>
                        <div className="col-md-8">
                            <div className="said">
                                “Senoark has aided in various marketing activities that have allowed us to reach new heights.”
                            </div>
                            <div className="name">Tamim Enam</div>
                            <div className="designation">Managing Director</div>
                            <div className="company">Federal Insurance Ltd</div>
                        </div>
                    </div>

                    <div className="T1 mt-5 row align-items-center">
                        <div className="col-md-4 text-center">
                            <img src={T2} alt="" className="testPicture"/>
                        </div>
                        <div className="col-md-8">
                            <div className="said">
                                “Senoark has helped our company grow in terms of sales and marketing for our internal projects.”
                            </div>
                            <div className="name">Ziaul Hassan</div>
                            <div className="designation">Managing Director</div>
                            <div className="company">System & Services Limited</div>
                        </div>
                    </div>

                    <div className="T1 mt-5 row align-items-center">
                        <div className="col-md-4 text-center">
                            <img src={T3} alt="" className="testPicture" />
                        </div>
                        <div className="col-md-8">
                            <div className="said">
                                “As a start-up, Senoark has helped us to get the exposure that we needed.”
                            </div>
                            <div className="name">Abrar Masum Shanto</div>
                            <div className="designation">Founder and CEO</div>
                            <div className="company">Rokkhi</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="blogs container">
                <div className="title">Blogs</div>
            </div> */}
        </div>
    )
}

export default Home;