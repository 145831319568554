import one from '../../assets/people/1.png'
import two from '../../assets/people/2.png'
import three from '../../assets/people/3.png'
import four from '../../assets/people/4.png'
import five from '../../assets/people/5.png'
import six from '../../assets/people/6.png'
import seven from '../../assets/people/7.png'
import eight from '../../assets/people/8.png'
import nine from '../../assets/people/9.png'
import ten from '../../assets/people/10.png'
import eleven from '../../assets/people/11.png'
import twelve from '../../assets/people/12.png'
import thirteen from '../../assets/people/13.png'
import fourteen from '../../assets/people/14.png'
import fifteen from '../../assets/people/15.png'
import sixteen from '../../assets/people/16.png'
import seventeen from '../../assets/people/17.png'
import eighteen from '../../assets/people/18.png'
import nineteen from '../../assets/people/19.png'
import twenty from '../../assets/people/20.png'
import './AboutUs.css';

function MTM() {
    return (
        <div className="mtm mt-5">
            <h1>MEET THE <span className='bold'>TEAM</span></h1>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <img src={one} alt="Maliha Rahham" className='img-fluid'/>
                        <p className='name'>Maliha Rahham</p>
                        <p className="post">Managing Partner & CEO, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={two} alt="Syed Joheb Hassan" className='img-fluid' />
                        <p className='name'>Ziaul Hassan</p>
                        <p className="post">Chief Advisor, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={three} alt="Promit Karmaker" className='img-fluid' />
                        <p className='name'>MBE Tameem</p>
                        <p className="post">Board of Director, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={four} alt="Ashraful Islam" className='img-fluid'/>
                        <p className='name'>Syed Joheb Hassan</p>
                        <p className="post">General Manager Operations, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={nineteen} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Promit Karmaker</p>
                        <p className="post">Managing Director, Aveneur Solutions</p>
                    </div>
                    <div className="col-md-3">
                        <img src={five} alt="Badiul Islam Fahad" className='img-fluid' />
                        <p className='name'>Md Ashraful Rahman</p>
                        <p className="post">Senior Creative Designer, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={six} alt="Sadia Armin Alam" className='img-fluid' />
                        <p className='name'>Badiul Alam Fahad</p>
                        <p className="post">Junior Creative Designer, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={seven} alt="Dilip Chandra Das" className='img-fluid' />
                        <p className='name'>Sadia Armin Alam</p>
                        <p className="post">Account Manager, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={eight} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Dilip Chandra Das</p>
                        <p className="post">Officer Manager, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={twenty} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Aminul Islam</p>
                        <p className="post">Full Stack Web Developer, Aveneur Solutions</p>
                    </div>
                    <div className="col-md-3">
                        <img src={nine} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Nusrat Jahan Jerin</p>
                        <p className="post">Head of Customer Services, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={ten} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Shamim Shahriar Jibon</p>
                        <p className="post">Project Executive, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={eleven} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Mim Akter Mitu</p>
                        <p className="post">Customer Service Executive, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={twelve} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Tayeba Akhter</p>
                        <p className="post">Customer Service Executive, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={thirteen} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Adnan Hossain</p>
                        <p className="post">Customer Service Executive, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={fourteen} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Afrin Badhon</p>
                        <p className="post">Customer Service Executive, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={fifteen} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Lutfhun Nahar</p>
                        <p className="post">Customer Service Executive, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={sixteen} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Nasrin Akhter</p>
                        <p className="post">Customer Service Executive, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={seventeen} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Mohammad Raju</p>
                        <p className="post">Customer Service Executive, Senoark Digital</p>
                    </div>
                    <div className="col-md-3">
                        <img src={eighteen} alt="Aminul Islam" className='img-fluid' />
                        <p className='name'>Shafiur Rahman</p>
                        <p className="post">Customer Service Executive, Senoark Digital</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MTM;