// import img1 from '../../assets/logos/1.png'
import img1 from '../../assets/logos/whitelogos/1.png';
import img2 from '../../assets/logos/whitelogos/2.png';
import img3 from '../../assets/logos/whitelogos/3.png';
import img4 from '../../assets/logos/whitelogos/4.png';
import img5 from '../../assets/logos/whitelogos/5.png';
import img6 from '../../assets/logos/whitelogos/6.png';
import img7 from '../../assets/logos/whitelogos/7.png';
import img8 from '../../assets/logos/whitelogos/8.png';
import './Apps.css';
import Marquee from 'react-fast-marquee'

function Apps() {

    return (
        <div className="apps mt-4">
            <Marquee gradient={false} loop={0} speed={100} pauseOnHover={true} className='marquee'>

                <div className="cards legalx">
                    <div className="row">
                        <div className="col-md-8">
                            <h4>LegalX</h4>
                            <p>LegalX is a SAAS-based solution that aims to bring legal access to the fingertips of the people at an affordable price.</p>
                        </div>
                        <div className="col-md-4">
                            <img src={img4} alt="img1" className='img-fluid'/>
                        </div>
                    </div>
                    <button type='button' className='button'>Visit Us</button>
                </div>

                <div className="cards newsark">
                    <div className="row">
                        <div className="col-md-8">
                            <h4>Newsark Headlines</h4>
                            <p>Newsark Headlines’ goal is to gather the latest news and disperse it among the people in the fastest time possible.</p>
                        </div>
                        <div className="col-md-4">
                            <img src={img6} alt="img1" className='img-fluid'/>
                        </div>
                    </div>
                    <button type='button' className='button'>Visit Us</button>
                </div>


                <div className="cards aveneur">
                    <div className="row">
                        <div className="col-md-8">
                            <h4>Aveneur Solutions</h4>
                            <p>Aveneur Solutions, founded in 2021, is an IT and engineering firm that believes in providing integrated and practical solutions to its clients by investing in innovation and technologies.</p>
                        </div>
                        <div className="col-md-4">
                            <img src={img1} alt="img1" className='img-fluid'/>
                        </div>
                    </div>
                    <button type='button' className='button'>Visit Us</button>
                </div>

                <div className="cards telecure">
                    <div className="row">
                        <div className="col-md-8">
                            <h4>Telecure</h4>
                            <p>Telecure is an online and offline-based emerging health-tech startup that aims to provide numerous services such as pathology & blood tests at an affordable price from the comfort of the customer's home.</p>
                        </div>
                        <div className="col-md-4">
                            <img src={img1} alt="img1" className='img-fluid'/>
                        </div>
                    </div>
                    <button type='button' className='button'>Visit Us</button>
                </div>

                <div className="cards senoark">
                    <div className="row">
                        <div className="col-md-8">
                            <h4>Eduark Global</h4>
                            <p>Eduark Global is an educational consultancy firm that focuses on student selection and placement process, which guarantees a smooth transition into studying abroad. </p>
                        </div>
                        <div className="col-md-4">
                            <img src={img3} alt="img1" className='img-fluid'/>
                        </div>
                    </div>
                    <button type='button' className='button'>Visit Us</button>
                </div>


                <div className="cards czar">
                    <div className="row">
                        <div className="col-md-8">
                            <h4>C'zar</h4>
                            <p>Located in the heart of Dhaka, C’zar is an Italian cuisine-based restaurant that offers fast food and other delicacies.</p>
                        </div>
                        <div className="col-md-4">
                            <img src={img2} alt="img1" className='img-fluid'/>
                        </div>
                    </div>
                    <button type='button' className='button'>Visit Us</button>
                </div>



                <div className="cards senoark">
                    <div className="row">
                        <div className="col-md-8">
                            <h4>Senoark Digital</h4>
                            <p>Senoark Digital is a Bangladesh-based Business Process Outsourcing (BPO) firm founded in 2019, which provides all kinds of digital services such as marketing support, lead generation, call-center support.</p>
                        </div>
                        <div className="col-md-4">
                            <img src={img8} alt="img1" className='img-fluid'/>
                        </div>
                    </div>
                    <button type='button' className='button'>Visit Us</button>
                </div>

                <div className="cards legalx">
                    <div className="row">
                        <div className="col-md-8">
                            <h4>LegalX Partners</h4>
                            <p>LegalX Partners is a law firm dedicated to providing its clientele with legal support such as documentation, preparing licenses, tax filing and more.</p>
                        </div>
                        <div className="col-md-4">
                            <img src={img5} alt="img1" className='img-fluid'/>
                        </div>
                    </div>
                    <button type='button' className='button'>Visit Us</button>
                </div>


                <div className="cards senoark">
                    <div className="row">
                        <div className="col-md-8">
                            <h5>Senoark Marketplace</h5>
                            <p>As an e-commerce website, Senoark Marketplace fulfills the need of customers for various business services and numerous merchandising products. </p>
                        </div>
                        <div className="col-md-4">
                            <img src={img7} alt="img1" className='img-fluid'/>
                        </div>
                    </div>
                    <button type='button' className='button'>Visit Us</button>
                </div>


            </Marquee>
        </div>
    )
};

export default Apps;