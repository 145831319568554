import './Mediacomm.css'

function Mediacomm() {
    return (
        <div className="media">
            <div className="banner row align-items-center">
                <div className="col-md-6">
                    <div className="writing">
                        <h1 className="heading">INTRODUCING NEWSARK HEADLINES</h1>
                        <p>The news that's happening now</p>
                        <a href="https://senoarknews.com" className='abutton'>Visit Now</a>
                    </div>
                </div>
                <div className="col-md-6"></div>
            </div>
        </div>
    )
}

export default Mediacomm;