import './Foods.css';

function Foods() {
    return (
        <div className="foods">
            <div className="foods-banner row align-items-center">
                <div className="col-md-6">
                    <div className="writing">
                        <h1 className="heading">INTRODUCING SENOARK FOODS</h1>
                        <p>Visit C'zar, one of our food ventures located at the heart of Dhaka</p>
                        <a href="https://www.facebook.com/CzarCorporate" className='abutton'>Visit Now</a>
                    </div>
                </div>
                <div className="col-md-6"></div>
            </div>
        </div>
    )
};

export default Foods;