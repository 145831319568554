import './AboutUs.css';

function MFC() {
    return (
        <div className="mfc mt-5">
            <div className="container">
                <h5 className="borderleft">Message from Chairman</h5>
                <p>The Chairman has played an important role in the Senoark Group since its beginnings. He is in charge of the Group's operations and is responsible for growing its commercial partnerships. He possesses good leadership and interpersonal abilities.</p>
                <p>His aggressive and customized approach to business, as well as his competitive attitude, have considerably contributed to the Group's and its different businesses' success.</p>
            </div>
        </div>
    )
};

export default MFC;