function WhoWeAre() {
    return (
        <div className="whoweare">
            <div className="full-width"></div>
            <div className="container mt-5">
                <h5 className="borderleft">Our Mission</h5>
                <p>Give individuals the ability to create communities and bring the globe together.</p>
                <h5 className="borderleft">About Senoark</h5>
                <p>Senoark is continually iterating, solving challenges, and collaborating to link individuals. As a result, it is critical that our team represents the variety of the individuals we serve. Hiring individuals from diverse backgrounds and perspectives allows us to make better decisions, produce better products, and provide better experiences for everyone.</p>
            </div>
        </div>
    )
};

export default WhoWeAre;