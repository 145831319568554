import './BlogCard.css';

function BlogCard(props) {
    return (
        <div className="blogcard">
            <div className="card">
                <img src={props.src}className="card-img-top" alt="blog" />
                <div className="card-body">
                    <h5 className="card-title">{props.title}</h5>
                    <p className="card-text">{props.text}</p>
                    <a href={props.link} className="blog-button">Read More</a>
                </div>
            </div>
        </div>
    )
}

export default BlogCard;