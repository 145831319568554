import './Discover.css';

function Discover() {
    return (
        <div className="discover mt-5">
            <div className="container">
                <div id="carouselExampleIndicators" className='carousel slide' data-bs-ride='carousel' data-bs-interval="3000">
                    <div className="carousel-indicators">
                        <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='0' className='active' aria-label='Slide 1'></button>
                        <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='1' aria-label='Slide 2'></button>
                        <button type='button' data-bs-target='#carouselExampleIndicators' data-bs-slide-to='2' aria-label='Slide 3'></button>
                    </div>

                    <div className="carousel-inner">
                        <div className="carousel-item active banner1">
                            <div className="carousel-caption">
                                <h1 className='heading'>Ensuring digital life for you</h1>
                                <p>Served more than 2,000 clients with our digital products</p>
                            </div>
                        </div>
                        <div className="carousel-item banner2">
                            <div className="carousel-caption">
                                <h1 className='heading'>The interconnected living of the future</h1>
                                <p>Grow your business digitally with our digital services today!</p>
                            </div>
                        </div>
                        <div className="carousel-item banner3">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Discover;